import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  feedHeader: {
    padding: theme.spacing(5, 2),
    maxWidth: 544,
    margin: "0 auto",
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    maxWidth: 1080,
    margin: "0 auto",
  },
  headerSubtitle: {
    maxWidth: 544,
    marginTop: theme.spacing(1),
  },
}))

export default useStyles
