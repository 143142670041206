import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"

import Link from "../general/Link"

import useStyles from "./rowTitle.style"

export default function RowTitle({ title, href }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.row}>
            <Typography
              className={classes.title}
              variant="body1"
              color="inherit"
            >
              {title}
            </Typography>
            {href && (
              <Hidden smDown>
                <Link className={classes.button} color="inherit" href={href}>
                  <Typography variant="button" color="inherit">
                    {t("blog.allArticles")}
                  </Typography>
                </Link>
              </Hidden>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
