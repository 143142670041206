import React from "react"

import Typography from "@material-ui/core/Typography"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout/layout"

import RowTitle from "../../components/general/rowTitle"
import ArticlesRow from "../../components/general/articlesRow"
import JoinBanner from "../../components/general/joinBanner"
import NewsletterBanner from "../../components/general/newsletterBanner"

import Tabs from "../../components/blog/tabs"

import useStyles from "./index.style"
import { graphql } from "gatsby"
import SEO from "../../components/seo"

export const query = graphql`
  query BlogFeed($language: String!) {
    allContentfulLanding(filter: { node_locale: { eq: $language } }) {
      nodes {
        blogTitle
        blogDescription
        joinBannerDescription
        joinBannerTitle
        bannerImage {
          title
          file {
            url
          }
        }
      }
    }
    allContentfulBlogPost(
      limit: 3
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { eq: "fr" } }
    ) {
      nodes {
        coverPicture {
          file {
            url
          }
        }
        seo {
          slug
        }
        category {
          name
          seo {
            slug
          }
        }
        author {
          name
        }
        title
        createdAt(fromNow: true, locale: $language)
        content {
          json
        }
      }
    }
    contentfulCategory(name: { eq: "Blog" }, node_locale: { eq: $language }) {
      seo {
        title
        description
      }
      subcategory {
        name
        seo {
          slug
        }
        category {
          name
        }
        blog_post {
          seo {
            slug
          }
          author {
            name
          }
          coverPicture {
            file {
              url
            }
          }
          title
          createdAt(fromNow: true, locale: $language)
          content {
            json
          }
        }
      }
    }
  }
`

export default function Feed({ data }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    allContentfulBlogPost: { nodes: lastPosts },
    contentfulCategory,
    allContentfulLanding: { nodes: landingData },
  } = data

  const allCategories = contentfulCategory.subcategory

  return (
    <Layout>
      <SEO
        title={contentfulCategory.seo.title}
        description={contentfulCategory.seo.description}
      />
      <Tabs categoryName="Blog" />
      <div className={classes.feedHeader}>
        <Typography variant="h1" color="initial" align="center">
          {landingData[0].blogTitle}
        </Typography>
        <Typography
          variant="button"
          component="p"
          color="initial"
          align="center"
        >
          {landingData[0].blogDescription}
        </Typography>
      </div>
      <RowTitle title={t("blog.lastArticles")} />
      <ArticlesRow
        data={lastPosts.map(post => ({
          ...post,
          href: `/blog/${post.category.seo.slug}/${post.seo.slug}`,
        }))}
      />
      {/* manual filter because of contentful bug */}
      {allCategories.map((category, index) => (
        <>
          {landingData[0] &&
            index === Math.round(allCategories.length / 2) - 1 && (
              <JoinBanner
                gutterBottom
                title={landingData[0].joinBannerTitle}
                description={landingData[0].joinBannerDescription}
                image={landingData[0].bannerImage}
              />
            )}
          {index === allCategories.length - 1 && (
            <NewsletterBanner gutterBottom />
          )}
          <RowTitle title={category.name} href={`/blog/${category.seo.slug}`} />
          <ArticlesRow
            data={category.blog_post
              .sort((a, b) => a.hours < b.hours)
              .slice(0, 3)
              .map(post => ({
                ...post,
                category,
                href: `/blog/${category.seo.slug}/${post.seo.slug}`,
              }))}
          />
        </>
      ))}

      {/* <RowTitle title="Le couple et le musulman 💛" />
      <ArticlesRow data={lastPosts} />
      <JoinBanner gutterBottom />
      <RowTitle title="Le comportement 👐" />
      <ArticlesRow data={lastPosts} />
      <RowTitle title="La science 🔬" />
      <ArticlesRow data={lastPosts} />
      <NewsletterBanner gutterBottom />
      <RowTitle title="Histoire 📓" />
      <ArticlesRow data={lastPosts} /> */}
    </Layout>
  )
}
