import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: "1080px",
    margin: "0 auto",
  },
  row: {
    width: "100%",
    borderBottom: "1px solid #FFC400",
    color: "#FFC400",
    height: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    textTransform: "uppercase",
  },
  button: {
    padding: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export default useStyles
